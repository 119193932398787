<template>
  <b-button-group>
    <b-button
      v-for="option in options"
      :key="`option_${option.value}`"
      :size="size"
      :variant="value === option.value ? 'primary' : 'white'"
      @click="selectOption(option)"
      v-b-tooltip.hover
      :title="option.title"
      class="text-nowrap"
      :class="option.class"
      :disabled="disabled"
    >
      <slot :option="option">
        {{ option.text }}
      </slot>
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'ButtonSelect',
  components: {},
  props: {
    value: {
      type: undefined,
    },
    options: {
      type: Array,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectOption(option) {
      if (this.value === option.value && this.clearable === true) {
        this.$emit('input', null);
        return;
      }
      this.$emit('input', option.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
